import type { ThemeConfig } from 'antd';
import { calculateHoverColor } from './utill';

const saturationDiff = 23; // +23%
const lightnessDiff = 4;

export function generateAntTheme(themeType: string = 'venn') {
  let primaryColor;

  switch (themeType) {
    case 'mac':
      primaryColor = '#00ACEF';
      break;
    case 'aspire':
      primaryColor = '#0083B5';
      break;
    case 'algonquin':
      primaryColor = '#FB7400';
      break;
    case 'regentsPark':
      primaryColor = '#58B4EB';
      break;
    case 'bozzuto':
      primaryColor = '#006747';
      break;
    case 'fernwood':
      primaryColor = '#84A783';
      break;
    case 'theDime':
      primaryColor = '#1E1218';
      break;
    case 'perennialcorktown':
      primaryColor = '#AF6431';
      break;
    case 'gowanuswharf':
      primaryColor = '#3471A7';
      break;
    case 'communityrealty':
      primaryColor = '#5F99BC';
      break;
    case 'venn':
      primaryColor = '#EA7555';
      break;
    case 'westhousetoronto':
      primaryColor = '#000000';
      break;
    case 'relatedlife':
      primaryColor = '#866E4B';
      break;
    case 'cim':
      primaryColor = '#00677F';
      break;
    case 'veris':
      primaryColor = '#444A40';
      break;
    default:
      primaryColor = '#EA7555';
      break;
  }

  const primaryColorHover = calculateHoverColor(primaryColor, saturationDiff, lightnessDiff);

  const theme: ThemeConfig = {
    token: {
      fontFamily: 'MarkPro',
      colorPrimary: primaryColor,
      colorPrimaryHover: primaryColorHover,
      colorTextHeading: '#272625',
      colorBgTextHover: '#D3D1CE00',
      colorBgContainerDisabled: '#F2F2F2',
      colorBorder: '#AEABA8',
      colorBgContainer: '#FFFFFF',
      colorTextDisabled: '#D3D1CE',
      colorSplit: '#E1DFDE',
      colorBorderSecondary: '#D3D1CE',
      colorTextPlaceholder: '#AEABA8',
      colorTextLightSolid: '#ffffff',
      controlItemBgActive: '#F2F2F2',
      fontSizeLG: 16,
      controlHeightLG: 40,
      borderRadiusLG: 8,
      controlPaddingHorizontal: 12,
      colorFillContent: '#D3D1CE',
      colorErrorHover: '#FF93A2',
      colorErrorOutline: '#FF273A',
      colorFillSecondary: '#D3D1CE',
      controlItemBgHover: '#F2F2F2',
      colorWarning: '#B28F24',
      colorWarningBorder: '#FFE699',
      colorWarningBg: '#FFFBEB',
      colorSuccess: '#268053',
      colorSuccessBorder: '#8ECCA3',
      colorSuccessBg: '#E5FFEE',
      colorInfo: '#98B3D9',
      colorInfoBorder: '#98B3D9',
      colorInfoBg: '#F0F2F6',
      colorError: '#FF273A',
      colorErrorBorder: '#FF4152',
      colorErrorBg: '#FFF2F4',
    },
    components: {
      Typography: {
        colorTextDescription: '#504D4B',
      },
      Button: {
        fontWeight: 500,
        colorLink: primaryColor,
        colorLinkHover: primaryColorHover,
        defaultBorderColor: '#AEABA8',
        defaultColor: '#3E3A37',
        textTextColor: '#3E3A37',
        borderColorDisabled: '#D3D1CE',
      },
      Checkbox: {
        controlInteractiveSize: 20,
      },
      Switch: {},
      Segmented:{
        itemSelectedColor: '#3E3A37',
        itemSelectedBg: '#FFFFFF',
        itemColor: '#3E3A37',
      },
      Tabs: {
        lineWidthBold: 3,
        itemHoverColor: primaryColorHover,
        colorTextDisabled: '#D3D1CE',
        horizontalItemGutter: 32,
      },
      Input: {
        hoverBorderColor: '#AEABA8',
        addonBg: '#F2F2F2',
        activeBorderColor: '#AEABA8',
        activeBg: '#FFF',
        hoverBg: '#FFFFFF',
        errorActiveShadow: 'none',
      },
      Select: {
        selectorBg: '#FFF',
        optionSelectedColor: '#3E3A37',
        optionSelectedBg: '#F2F2F2',
        optionActiveBg: '#F2F2F2',
        multipleItemBorderColor: 'rgba(0, 0, 0, 0.00)',
        multipleItemBg: '#F2F2F2',
        multipleSelectorBgDisabled: '#F2F2F2',
        multipleItemColorDisabled: '#D3D1CE',
        clearBg: '#FFFFFF',
        hoverBorderColor: '#AEABA8',
        activeBorderColor: '#AEABA8',
      },
      Steps: {
        iconSize: 32,
      },
      Breadcrumb: {
        linkColor: '#D3D1CE',
        linkHoverColor: '#AEABA8',
        separatorColor: '#D3D1CE',
        itemColor: '#D3D1CE',
        lastItemColor: '#FFFFFF',
      },
      Table: {
        borderColor: '#D3D1CE',
        headerBg: '#FFFFFF',
        headerSplitColor: '#FFFFFF',
      },
      Drawer: {
        footerPaddingBlock: 24,
      },
    },
  };

  return theme;
}
