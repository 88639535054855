type HSL = {
  h: number; // Hue (0-360)
  s: number; // Saturation (0-100)
  l: number; // Lightness (0-100)
};

/**
 * Converts a HEX color to HSL.
 * @param hex - The HEX color string.
 * @returns The HSL representation.
 */
function hexToHsl(hex: string): HSL {
  const r = parseInt(hex.slice(1, 3), 16) / 255;
  const g = parseInt(hex.slice(3, 5), 16) / 255;
  const b = parseInt(hex.slice(5, 7), 16) / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const delta = max - min;

  let h = 0;
  if (delta !== 0) {
    if (max === r) h = ((g - b) / delta) % 6;
    else if (max === g) h = (b - r) / delta + 2;
    else h = (r - g) / delta + 4;
  }
  h = Math.round(h * 60);
  if (h < 0) h += 360;

  const l = (max + min) / 2;
  const s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

  return {
    h: Math.round(h),
    s: Math.round(s * 100),
    l: Math.round(l * 100),
  };
}

/**
 * Converts an HSL color to HEX.
 * @param hsl - The HSL color representation.
 * @returns The HEX color string.
 */
function hslToHex(hsl: HSL): string {
  const { h, s, l } = hsl;

  const a = (s * Math.min(l / 100, 1 - l / 100)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = l / 100 - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0');
  };

  return `#${f(0)}${f(8)}${f(4)}`;
}

/**
 * Calculates the hover color based on the primary color.
 * Handles scaling for very dark or very light colors.
 * @param primaryHex - The primary HEX color.
 * @param saturationDiff - The saturation difference.
 * @param lightnessDiff - The lightness difference.
 * @returns The hover HEX color.
 */
export function calculateHoverColor(
  primaryHex: string,
  saturationDiff: number,
  lightnessDiff: number,
): string {
  const primaryHsl = hexToHsl(primaryHex);

  const hoverHsl: HSL = {
    h: primaryHsl.h,
    s: Math.min(primaryHsl.s + saturationDiff, 100), // Ensure saturation doesn't exceed 100%
    l: Math.min(
      primaryHsl.l + lightnessDiff * (primaryHsl.l < 50 ? 1.5 : 1), // Scale more for dark colors
      100,
    ), // Ensure lightness doesn't exceed 100%
  };

  return hslToHex(hoverHsl);
}
